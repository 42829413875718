import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  Phone,
  VideoAndText,
  BannerLinks,
  BannerSmall,
  ProjectPagination
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.tiwag.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.stadtamthall.png'
import image5 from '../../assets/bannerlinks/bannerbox.wedl.jpg'

const TiwagWaermepumpe = () => {
  const links = generateBreadcrumbsLinks('/projekte/tiwag-waermepumpe', 'TIWAG Wärmepumpe')
  return (
    <Layout margin={false} color="black">
      <Helmet
        title="3D Animation für die Tiwag"
        meta={[
          {
            name: 'description',
            content:
              'Um die Komplexität einer Wärmepumpe für jeden verständlich zu veranschaulichen haben wir ein Neurendering der 3D Animation umgesetzt.'
          },
          {
            name: 'keywords',
            content:
              'Tiwag holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, TIWAG, tiwag, 3D Animation holzweg, 3D Animation, 3D Film, Wasserkraftwerk, Wärmepumpe, 3D Wärmepumpe, '
          },
          {
            property: 'og:description',
            content:
              'Um die Komplexität einer Wärmepumpe für jeden verständlich zu veranschaulichen haben wir ein Neurendering der 3D Animation umgesetzt.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.tiwag-c6cac685f0a608379fe38844fa95b597.jpg'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Nutzung von Umgebungswärme" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            3D Animation<br />für die TIWAG
          </div>
        }
        text={
          <span>
            <p>Um die Komplexität einer Wärmepumpe für jeden verständlich zu veranschaulichen haben wir ein Neurendering der 3D Animation umgesetzt.</p>
            <p>Gemeinsam mit unserem Projektpartner wurden folgende Änderungen an der 3D-Animation durchgeführt:</p>
            <ul>
              <li>Start mit Überarbeitung des Storybords der verschiedenen Abschnitte mit kleinen inhaltlichen Änderungen</li>
              <li>Bestehende Files wurden in einer FullHD 1920x1080 Pixel nachhaltig neu gerendert</li>
              <li>Konsolidierung der bestehenden Files</li>
              <li>das Design wurde im Punkto Look &amp; Feel modernisiert</li>
              <li>das Shading wurde überarbeitet</li>
              <li>neue Texteinblendungen für die Messe</li>
            </ul>
          </span>
        }
      />
      <VideoAndText
        YTvideo="afSlke5OSe0"
        headline="Die Animation:"
        text=""
      />
      <ProjectPagination
        titleLeft="Stadtamt Hall Relaunch"
        imageLeft={image4}
        leftLink="/projekte/stadtamthall"
        titleRight="Wedl Handelshaus"
        imageRight={image5}
        rightLink="/projekte/wedl"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default TiwagWaermepumpe
